import React from "react"

import "./src/styles/main.scss"

import Layout from "./src/components/layout/Layout.component"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

export const wrapPageElement = ({ element }) => <Layout>{element}</Layout>
