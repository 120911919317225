import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Email from "../../images/social-media/email.svg"
import Instagram from "../../images/social-media/instagram.svg"
import LinkedIn from "../../images/social-media/linkedin.svg"

const Connect = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          socialMedia {
            email
            instagram
            linkedin
          }
        }
      }
    }
  `)

  const { email, instagram, linkedin } = data.site.siteMetadata.socialMedia

  return (
    <div className="connect">
      <span className="connect-header">Connect with us</span>
      <ul>
        <li>
          <a href={`mailto:${email}`}>
            <div className="icon">
              <img src={Email} alt="Email" />
            </div>
            <span>{email}</span>
          </a>
        </li>
        <li>
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            <div className="icon">
              <img src={Instagram} alt="Instagram" />
            </div>
            <span>Instagram</span>
          </a>
        </li>
        <li>
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <div className="icon">
              <img src={LinkedIn} alt="LinkedIn" />
            </div>
            <span>LinkedIn</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Connect
