import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api"

// import { MAP_COLORS } from "./MAP_COLORS"
import GatsbyImage from "../gatsbyImage/GatsbyImage.component"

// const mapContainerStyles = {
//   height: "100%",
//   width: "100%",
// }

// const DOBACOR_LOCATION = {
//   lat: 34.07644,
//   lng: -118.38379,
// }

// const mapOptions = {
//   styles: MAP_COLORS,
//   disableDefaultUI: true,
//   zoomControl: true,
// }

const Map = () => {
  const data = useStaticQuery(graphql`
    {
      map: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "map.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 660) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <a
      href="https://goo.gl/maps/kmpK1b4kncKACMYT8"
      target="_blank"
      rel="noopener noreferrer"
      className="map-container"
    >
      <aside className="map">
        <GatsbyImage image={data.map.childImageSharp.fluid} />
        {/* <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={mapContainerStyles}
          center={DOBACOR_LOCATION}
          zoom={15}
          options={mapOptions}
        >
          <Marker position={DOBACOR_LOCATION} />
        </GoogleMap>
      </LoadScript> */}
      </aside>
    </a>
  )
}

export default Map
