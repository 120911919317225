/* eslint-disable */
import React, { useState } from "react"
import { Link } from "gatsby"
import { AnimatePresence } from "framer-motion"

import Dobacor from "../../images/dobacor.svg"
import Burger from "../../images/burger.svg"
import Mobile from "./Mobile.component"

const Header = () => {
  const [mobile, setMobile] = useState(false)

  return (
    <header className="header">
      <Link to="/">
        <img src={Dobacor} alt="Dobacor logo" className="logo" />
      </Link>
      <nav className="desktop">
        <ul>
          <li>
            <Link to="/about" activeClassName="active" partiallyActive={true}>
              About
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              activeClassName="active"
              partiallyActive={true}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="/partners"
              activeClassName="active"
              partiallyActive={true}
            >
              Partners
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="active" partiallyActive={true}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      <nav className="mobile">
        <div onClick={() => setMobile(true)} className="burger">
          <img src={Burger} alt="Burger" />
        </div>
        <AnimatePresence>
          {mobile && <Mobile setMobile={setMobile} />}
        </AnimatePresence>
      </nav>
    </header>
  )
}

export default Header
