import React from "react"

import Connect from "./Connect.component"
import Map from "./Map.component"

const Contact = () => {
  return (
    <section className="contact">
      <address>
        <div className="location">
          <h3>Dobacor Management LLC</h3>
          <h1>
            <span>141 S Robertson Blvd, Suite 200</span>
            <span>Los Angeles, CA 90048</span>
          </h1>
        </div>
        <Connect />
      </address>
      <Map />
    </section>
  )
}

export default Contact
