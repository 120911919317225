import React from "react"

// import PageTransition from "../pageTransition/PageTransition.component"
import Header from "../header/Header.component"
import Contact from "../contact/Contact.component"
import Footer from "../footer/Footer.component"

const Layout = ({ children }) => {
  return (
    <>
      {/* <PageTransition /> */}
      <Header />
      {children}
      <Contact />
      <Footer />
    </>
  )
}

export default Layout
