/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import Dobacor from "../../images/dobacor.svg"
import Close from "../../images/close.svg"

const Mobile = ({ setMobile }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: [0.165, 0.84, 0.44, 1] }}
      className="menu"
    >
      <div className="top-bar">
        <Link to="/" onClick={() => setMobile(false)} className="mobile-logo">
          <img src={Dobacor} alt="Dobacor logo" />
        </Link>
        <div onClick={() => setMobile(false)} className="close">
          <img src={Close} alt="Close" />
        </div>
      </div>
      <ul>
        <li>
          <Link
            onClick={() => setMobile(false)}
            to="/about"
            activeClassName="active"
          >
            About
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setMobile(false)}
            to="/projects"
            activeClassName="active"
            partiallyActive={true}
          >
            Projects
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setMobile(false)}
            to="/partners"
            activeClassName="active"
          >
            Partners
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setMobile(false)}
            to="/contact"
            activeClassName="active"
          >
            Contact
          </Link>
        </li>
      </ul>
    </motion.div>
  )
}

export default Mobile
