import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <span>&copy; Copyright Dobacor Management LLC</span>
      <ul className="links">
        <li>
          <Link activeStyle={{ color: "rgb(16, 16, 16)" }} to="/privacy">
            Privacy
          </Link>
        </li>
        <li>
          <Link activeStyle={{ color: "rgb(16, 16, 16)" }} to="/legal-notice">
            Legal Notice
          </Link>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
